document.querySelectorAll('.article-wrapper').forEach((article, i) => {
    article.querySelectorAll('.note__link').forEach((link) => {
        if(link){
            link.setAttribute('href', `${link.getAttribute('href')}_${i}`);
            link.setAttribute('data-href', `${link.getAttribute('data-href')}_${i}`);
        }
    });
});

if (document.querySelectorAll('.note__link').length) {
    document.querySelectorAll('.note__link').forEach(function (note) {
        note.addEventListener('click', function(e) {
            e.preventDefault();
            document.querySelector(`[href=${this.getAttribute('data-href')}]`).scrollIntoView({behavior: "smooth", block: "center"});
        });
    });
}