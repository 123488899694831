//Zdrapka, Scratch-card
'use strict';

var scratches = $('.scratch-card').each(function () {
  //sprawdzamy, czy jest ustawiony id dla statystyk
  var $artID = $(this).parents('.__js_stats_wrapper').data('id'),
    ga360id = $(this).data('ga360-id'),
    $galID = $(this).data('id');
  if (ga360id == undefined) {
    console.log(
      '%c dodać ga360-id do Zdrapki: ' + $(this).data('id'),
      'background: #553311; padding: 3px; color: #ffaaaa'
    );
  } else {
    statElementCollector[ga360id + '-' + $artID] = {
      id: ga360id,
      moduleName: $(this).data('id'),
    };
  }
  //pozycje
  slidePositionVieved[ga360id + '-' + $artID] = {};

  if (!ga360id) {
    console.log(
      '%c dodać ga360-id do zdrapki! ' +
        $galID +
        'background: #3b3b3b; padding: 3px; color: #beeb2f'
    );
  }

  var containerEl = $(this)[0],
    overlayImgUrl = $(this).children('img').attr('data-img'),
    brushImgUrl = 'https://hair-expert-longform-2021.wpcdn.pl/img/brush.png',
    revealPercent = 75;

  // event handler that fades out the overlay
  function onReveal() {
    containerEl.className += ' revealed';

    // NOTE: really weird workaround to force Edge 16 to actually detect the css class name addition and render the fade out transition. getElementById doesn't work, it actually needs to be getElementsByTagName. Any style could be changed (like fontSize) to trigger the update
    if (window.navigator.userAgent.indexOf('Edge') > -1) {
      document.getElementsByTagName('canvas')[0].style.width = '100%';
    }
  }

  function move() {
    sendStats('scratch-card', 'click', $artID + ' / ' + $galID);

    if (!isScrolligFlag) {
      WP.push(function () {
        WP.pwp.customAction({
          ga: {
            event: 'content_interaction',
            eventCategory: ga360id,
            moduleName: $galID,
            eventAction: deviceTouch ? 'tap' : 'click',
          },
        });
      });
    }
    // containerEl.classList.add('moved');
    containerEl.classList.remove('mouseon');
  }

  function singleMove() {
    containerEl.classList.add('mouseon');
  }

  if (ScratchIt.isSupported()) {
    ScratchIt(
      containerEl,
      overlayImgUrl,
      brushImgUrl,
      onReveal,
      revealPercent,
      move,
      singleMove
    );
  }
});
