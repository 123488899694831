'use strict';

(function () {
  document
    .querySelector('.header--nav')
    .insertAdjacentHTML(
      'afterbegin',
      '<button class="nav__btn nav--up"></button>'
    );
  document
    .querySelector('.header--nav')
    .insertAdjacentHTML(
      'beforeend',
      '<button class="nav__btn nav--down"></button>'
    );

  const list = document.querySelector('.header--nav ul');
  const listContainer = document.querySelector('.header--nav .ul-container');
  const nav = document.querySelector('.header--nav');
  const header = document.querySelector('.header');
  const menu = document.querySelector('.header--hamburger');
  const arrowDown = document.querySelector('.nav--down');
  const arrowUp = document.querySelector('.nav--up');

  let scrollNav = function (direction) {
    let offset = listContainer.scrollTop;
    let offsetMove = list.querySelectorAll('li')[0].offsetHeight;
    listContainer.scroll({
      top: offset + offsetMove * direction,
      left: 0,
      behavior: 'smooth',
    });
    offset = listContainer.scrollTop;
  };

  let scrollUp = function () {
    let elFocused = document.activeElement;
    let indexOfFocused = Array.from(list.querySelectorAll('li')).indexOf(
      elFocused.closest('li')
    );
    if (indexOfFocused > 0) {
      elFocused.blur();
      list
        .querySelectorAll('li')
        [indexOfFocused - 1].querySelector('a')
        .focus();
    }
  };

  let scrollDown = function () {
    let elFocused = document.activeElement;
    let indexOfFocused = Array.from(list.querySelectorAll('li')).indexOf(
      elFocused.closest('li')
    );
    if (indexOfFocused < list.querySelectorAll('li').length - 1) {
      elFocused.blur();
      list
        .querySelectorAll('li')
        [indexOfFocused + 1].querySelector('a')
        .focus();
    }
  };

  let setVisibilityOfBtns = function () {
    // scrollTop + offsetHeight = scrollHeight
    if (listContainer.scrollTop < 1) {
      arrowUp.classList.add('nav__btn--hidden');
    } else {
      arrowUp.classList.remove('nav__btn--hidden');
    }
    if (
      listContainer.scrollTop + listContainer.offsetHeight ===
      listContainer.scrollHeight
    ) {
      arrowDown.classList.add('nav__btn--hidden');
    } else {
      arrowDown.classList.remove('nav__btn--hidden');
    }
  };

  let setNavPosition = function () {
    if (listContainer.offsetHeight < listContainer.scrollHeight) {
      let elActive = list.querySelector('li.__active');
      let indexOfActive = Array.from(list.querySelectorAll('li')).indexOf(
        elActive
      );
      let setHeightOfLis = 0;
      if (indexOfActive > 0) {
        if (window.innerWidth > 1180) {
          setHeightOfLis =
            Math.floor(
              list.querySelectorAll('li')[indexOfActive].getBoundingClientRect()
                .top - document.body.getBoundingClientRect().top
            ) -
            Math.floor(
              list.querySelectorAll('li')[0].getBoundingClientRect().top -
                document.body.getBoundingClientRect().top
            );
        } else {
          for (let i = 0; i < indexOfActive; i++) {
            setHeightOfLis += list.querySelectorAll('li')[i].offsetHeight;
          }
        }
      }
      listContainer.scroll({
        top: setHeightOfLis,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  arrowDown.addEventListener('click', function () {
    scrollNav(1);
  });

  arrowUp.addEventListener('click', function () {
    scrollNav(-1);
  });

  listContainer.addEventListener('scroll', function () {
    setVisibilityOfBtns();
  });

  menu.addEventListener('click', function () {
    setNavPosition();
    setVisibilityOfBtns();
    this.classList.toggle('__js_isActive');
    document.documentElement.classList.toggle('hidden');
    nav.classList.toggle('__js_isActive');
    header.classList.toggle('__js_isActive');
    list.querySelector('li.__active a').focus();
  });

  list.addEventListener('click', function () {
    document.documentElement.classList.remove('hidden');
    nav.classList.remove('__js_isActive');
    header.classList.remove('__js_isActive');
    menu.classList.remove('__js_isActive');
  });

  header.addEventListener('mouseleave', function () {
    document.documentElement.classList.remove('hidden');
    menu.classList.remove('__js_isActive');
    nav.classList.remove('__js_isActive');
    this.classList.remove('__js_isActive');
  });

  window.addEventListener('resize', function () {
    setNavPosition();
    setVisibilityOfBtns();
  });

  if (!deviceTouch) {
    list.querySelectorAll('li a').forEach((el) => {
      el.addEventListener('click', function () {
        setTimeout(function () {
          document
            .querySelector(
              '.article-wrapper[data-id="' + el.getAttribute('data-slug') + '"]'
            )
            .querySelector('.social__btn')
            .focus();
        }, 1000);
      });
    });
  }

  window.addEventListener('keydown', function (evt) {
    evt = evt || window.event;
    let isEscape = false;
    let isArrowUp = false;
    let isArrowDown = false;
    // console.log(evt.key);
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
      isArrowUp = evt.key === 'ArrowUp';
      isArrowDown = evt.key === 'ArrowDown';
    } else {
      isEscape = evt.keyCode === 27;
      isArrowUp = evt.keyCode === 38;
      isArrowDown = evt.keyCode === 40;
    }
    if (isEscape) {
      document.documentElement.classList.remove('hidden');
      nav.classList.remove('__js_isActive');
      header.classList.remove('__js_isActive');
      menu.classList.remove('__js_isActive');
      menu.blur();
    }
    if (isArrowUp) {
      scrollUp();
    }
    if (isArrowDown) {
      scrollDown();
    }
  });
})();
