'use strict';

var galleriesHover = $('.gallery--hover').each(function () {
  //sprawdzamy, czy jest ustawiony id dla statystyk
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  var ga360id = $(this).data('ga360-id');
  if (ga360id == undefined) {
    console.log(
      '%c dodać ga360-id do Galerii hover: ' + $(this).data('id'),
      'background: #553311; padding: 3px; color: #ffaaaa'
    );
  } else {
    statElementCollector[ga360id + '-' + $artID] = {
      id: ga360id,
      moduleName: $(this).data('id'),
      viewedCount: 0,
      itemCount: $(this).find('.gallery--hover-item').length,
    };
  }
  $(this).statVievedSlide = [];
  //pozycje

  slidePositionVieved[ga360id + '-' + $artID] = {};
});

var itemHover = function (item) {
  var curElement = item;
  $(item).addClass('item--active');
  setTimeout(function () {
    $(curElement).find('.gallery--hover-arrow').addClass('tapped');
  }, 500);
  if (isScrolling) return;
  var $artID = $(item).parents('.__js_stats_wrapper').data('id'),
    $galID = $(item).parents('.gallery--hover').data('id'),
    $imgID = $(item).find('.gallery--hover-img').attr('src'),
    ga360id = $(item).parents('.gallery--hover').data('ga360-id'),
    $elId = $(item).data('item');
  if (!ga360id) {
    console.log(
      '%c dodać ga360-id do patchworka!' +
        $galID +
        'background: #3b3b3b; padding: 3px; color: #beeb2f'
    );
  }
  var activeItem = $(item).index();
  //console.log('art:' + $artID + 'gal:' + $galID + ' / img: ' + $imgID);
  slidePositionVieved[ga360id + '-' + $artID][activeItem] = 1;
  statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
    slidePositionVieved[ga360id + '-' + $artID]
  ).length;

  sendStats('gallery', 'hover-' + $elId, $artID + ' / ' + $galID, $imgID);

  if (!isScrolligFlag) {
    WP.push(function () {
      WP.pwp.customAction({
        ga: {
          event: 'content_interaction',
          eventCategory: ga360id,
          moduleName: $galID,
          eventAction: deviceTouch ? 'tap' : 'hover',
        },
      });
    });
  }
};

let eventName = deviceTouch ? 'click' : 'mouseenter';

$('.gallery--hover').on(eventName, '.gallery--hover-item', function (ev) {
  itemHover($(this));
});

$('.gallery--hover').on('mouseleave', '.gallery--hover-item', function () {
  $(this).removeClass('item--active');
  $(this).find('.gallery--hover-arrow').removeClass('tapped');
});

document.querySelectorAll('.gallery--hover-arrow').forEach((el) => {
  el.addEventListener('click', (ev) => {
    ev.stopPropagation();
    if (el.classList.contains('tapped')) {
      el.closest('.gallery--hover-item').classList.remove('item--active');
      el.classList.remove('tapped');
    } else {
      itemHover(el.closest('.gallery--hover-item'));
    }
  });
});
