var activeSlug;
var isScrolling = false, // gdy strona się scrolluje to zmienna ma wartość true i nie wysyłamy statów na hover np. akordeon, hotpoint
  lastReportedSlug = null;
var wp_content_selector;

(function () {
  if (
    !document.getElementsByClassName('__js_section').length ||
    document.location.href.indexOf('.html') > 0
  )
    return;

  var $sections = $('.__js_section'),
    initSlug =
      document.location.pathname.split('/').pop() ||
      $('.__js_section').eq(0).attr('id');

  activeSlug = initSlug;
  var customOffset = $('.header').height() + 10,
    animationTime = 1000,
    // Przewijanie do sekcji slug jeżeli w URL jest ustawiony jakiś slug na starcie

    scrollOnStart = function () {
      $('html,body').animate(
        {
          scrollTop: $('#' + initSlug).offset().top - customOffset + 10,
        },
        animationTime,
        'easeOutExpo'
      );

      sendStats('progress', 'start', initSlug);

      changeActiveSlug(initSlug);
      initNavigation();
    },
    // staty pageviews i scrollboost pwp ----- {

    reportSlugTS = -1,
    reportSlug = function () {
      if (lastReportedSlug === null) {
        //wyślij statystykę pageview standard

        wp_dot_addparams.ctags = statData[activeSlug].tags;
        WP.push(function () {
          WP.pwp.viewPage({
            ga: {
              event: 'pageview',
              page: {
                pageviewType: 'standard',
                error404: basicStatData.ga.page.error404,
                templateType: 'AS',
              },
              site: {
                name: basicStatData.ga.site.name,
                version: basicStatData.ga.site.version,
                cms: basicStatData.ga.site.cms,
                as: {
                  orderId: asorderid,
                  productName: asproduct,
                  name: asname,
                },
              },
              content: {
                title: statData[activeSlug].title,
                category: {
                  IAB: statData[activeSlug].category.IAB,
                  Gemius: statData[activeSlug].category.Gemius,
                },
                tags: statData[activeSlug].tags,
              },
            },
          });
        });
      } else {
        // wyślij statystykę pageview scrolboost vpv. pageviewType ma wartość "overlay"
        // gdy nie mamy doładowania i "scrollboost" gdy następuje doładowanie, ALE dla pseudo dynamicznych stron AS ma byc jednak scrollboost ;) - ustalone z Salestube !

        wp_content_selector = document.getElementById(lastReportedSlug);

        WP.push(function () {
          WP.stat.show(
            wp_sn,
            {
              category: 'pageviewVpv',
              label: 'scrollboost',
              asproduct: asproduct,
              asorderid: asorderid,
              asname: asname,
            },
            'pageviewVpv'
          );
        });

        //sendStats('pageviewVpv', 'scrollboost', activeSlug); // musi isć dot ptrzed ustawieniem wp_content_selector

        unloadData(lastReportedSlug);
        wp_dot_addparams.ctags = statData[activeSlug].tags;
        WP.push(function () {
          WP.pwp.viewPage({
            ga: {
              event: 'pageviewVpv',
              page: {
                pageviewType: 'scrollboost',
                error404: basicStatData.ga.page.error404,
                templateType: 'AS',
              },
              site: {
                name: basicStatData.ga.site.name,
                version: basicStatData.ga.site.version,
                cms: basicStatData.ga.site.cms,
                as: {
                  orderId: asorderid,
                  productName: asproduct,
                  name: asname,
                },
                frontendPlatform: 'other',
              },
              content: {
                title: statData[activeSlug].title,
                category: {
                  IAB: statData[activeSlug].category.IAB,
                  Gemius: statData[activeSlug].category.Gemius,
                },
                tags: statData[activeSlug].tags,
              },
              //"content": statData[activeSlug]
            },
          });
        });
      }

      lastReportedSlug = activeSlug;
      reportSlugTS = -1;
    },
    // ----- }

    changeActiveSlug = function (slug) {
      activeSlug = slug;

      if (getActiveSlugIndex() == 0) {
        //slug = "/";
        var prefix = 'order';
        var classes = document
          .querySelector('.header--nav')
          .className.split(' ')
          .filter(function (c) {
            return c.lastIndexOf(prefix, 0) !== 0;
          });
        document.querySelector('.header--nav').className = classes
          .join(' ')
          .trim();
        var prefix = 'order';
        var classes = document
          .querySelector('.header--nav')
          .className.split(' ')
          .filter(function (c) {
            return c.lastIndexOf(prefix, 0) !== 0;
          });
        document.querySelector('.header--nav').className = classes
          .join(' ')
          .trim();
        $('.header--nav').addClass('order_' + 1); // == 0;
      }

      $('.header--nav [data-slug]').parents('li').removeClass('__active');
      $('.header--nav [data-slug=' + activeSlug + ']')
        .parents('li')
        .addClass('__active');
      var prefix = 'order';
      var classes = document
        .querySelector('.header--nav')
        .className.split(' ')
        .filter(function (c) {
          return c.lastIndexOf(prefix, 0) !== 0;
        });
      document.querySelector('.header--nav').className = classes
        .join(' ')
        .trim();
      $('.header--nav').addClass('order_' + (getActiveSlugIndex() + 1));

      if (document.location.search.length > 0) {
        history.pushState(
          {
            id: slug,
          },
          $(this).attr('title'),
          slug + document.location.search
        );
      } else {
        history.pushState(
          {
            id: slug,
          },
          $(this).attr('title'),
          slug
        );
      }
      var subtitle = statData[activeSlug].title
        ? statData[activeSlug].title + ' - '
        : '';

      if (document.querySelector('title') && statData[activeSlug]) {
        document.querySelector('title').innerText = subtitle + defaultSiteTitle;
      }
      if (
        document.querySelector('meta[property="og:title"]') &&
        statData[activeSlug]
      ) {
        document.querySelector('meta[property="og:title"]').content =
          subtitle + defaultSiteTitle;
      }
      if (
        document.querySelector('meta[name="twitter:title"]') &&
        statData[activeSlug]
      ) {
        document.querySelector('meta[name="twitter:title"]').content =
          subtitle + defaultSiteTitle;
      }

      if (lastReportedSlug === null) {
        // jeżeli pierwsze wejście to wysyłaj pageview od razu
        reportSlug();
      } else if (lastReportedSlug != slug) {
        // jeżeli zmiana sluga to wysyłaj po sekundzie statystykę

        if (reportSlugTS > -1) clearTimeout(reportSlugTS);
        reportSlugTS = setTimeout(reportSlug, 1000);
      } else {
        if (reportSlugTS > -1) clearTimeout(reportSlugTS);
      }
    },
    // Sprawdzanie numeru sluga, domyślnie aktualnego
    getActiveSlugIndex = function (slug) {
      if (!slug) slug = activeSlug;
      return $('.header--nav [data-slug=' + slug + ']')
        .parent()
        .parent()
        .index();
    },
    // Sprawdzanie czy user zescrollował do sekcji. Jeżeli tak to ustawia pozycję w nawigacji
    sectionScrollCheck = function () {
      sectionScrollCheckTS = setTimeout(sectionScrollCheck, 200);

      var $W = $(window),
        topScroll = $W.scrollTop(),
        wHeight = $W.height();

      // wymuszenie strony głównej
      if (
        topScroll <= customOffset &&
        document.location.pathname.split('/').pop().length < 1
      ) {
        changeActiveSlug($sections.eq(0).attr('id'));
        return;
      }

      for (var i = 0; i < $sections.length; i++) {
        var $section = $sections.eq(i),
          sectionOffset = $section.offset().top,
          sectionHeight = $section.height();

        if (
          activeSlug != $section.attr('id') &&
          topScroll > sectionOffset - customOffset - 10 &&
          topScroll < sectionOffset + sectionHeight - customOffset
        ) {
          changeActiveSlug($section.attr('id'));
          break;
        }
      }
    },
    sectionScrollCheckTS = null,
    // Inicjalizacja metod dla obsługi nawigacji - clików i sprawdzanie scrolla, dodanie flagi podczas skrolowania
    initNavigation = function () {
      sectionScrollCheckTS = setTimeout(sectionScrollCheck, animationTime + 25);

      $('.header--nav [data-slug]').on('click', function (e) {
        isScrolling = true;

        // console.log('slug: ' + slug + ', activeSlug: ' + activeSlug + ', lastReportedSlug: ' + lastReportedSlug);

        if (sectionScrollCheckTS) clearTimeout(sectionScrollCheckTS);

        e.preventDefault();
        var $this = $(this),
          slug = $(this).data('slug');
        if (!slug.length) slug = '/';

        changeActiveSlug(slug);

        $('html,body').animate(
          {
            scrollTop:
              getActiveSlugIndex() > 0
                ? $('#' + slug).offset().top - customOffset + 10
                : 0,
          },
          animationTime,
          'easeInOutExpo',
          function () {
            isScrolling = false;
          }
        );
        sectionScrollCheckTS = setTimeout(
          sectionScrollCheck,
          animationTime + 25
        );

        // przewinięcie głównego zdjęcia po autoscrollu z nawigacji
        // $('html,body').delay(370).animate({scrollTop: $('#' + slug).find('h1').offset().top - 120 }, 820, 'easeInOutExpo')
      });
    };

  if (initSlug.length > 1) {
    setTimeout(scrollOnStart, 800);
  }
  // if (document.location.pathname.split('/').pop().length < 1) {
  //   initNavigation();
  // }
})();
