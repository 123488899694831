'use strict';

$.extend($.easing, {
  easeOutExpo: function (x, t, b, c, d) {
    return t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
  },
  easeInOutExpo: function (x, t, b, c, d) {
    if (t == 0) return b;
    if (t == d) return b + c;
    if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  },
});

if (localStorage.getItem('WPcrrf') === null) {
  localStorage.setItem('WPcrrf', '');
}

// zainicjowanie owli

var owlText = $('.owl-text');
var owlTimeline = $('.owl-timeline');
var owlPhoto = $('.owl-photo');

owlText.owlCarousel({
  loop: true,
  nav: true,
  center: true,
  margin: 10,
  responsive: {
    0: { items: 1, margin: 1 },
    641: { items: 2, margin: 10 },
    1141: { items: 4, margin: 10 },
    1281: { items: 5, margin: 10 },
  },
});

owlTimeline.owlCarousel({
  loop: true,
  nav: true,
  dots: false,
  center: true,
  margin: 10,
  responsive: {
    0: { items: 1, margin: 1 },
    641: { items: 2, margin: 10 },
    1141: { items: 4, margin: 10 },
    1281: { items: 5, margin: 10 },
  },
});

owlPhoto.owlCarousel({
  loop: true,
  nav: true,
  center: true,
  responsive: {
    0: { items: 1, margin: 1 },
    881: { items: 3, margin: 10 },
    1481: { items: 5, margin: 10 },
  },
});

//Obiekt zbierający informacje o elementach typu banner, slider, before/after, których statystyki należy wysłać w unload
var statElementCollector = new Object();
var ga360statElementsInit = function (el) {
  alert($(el).data('ga360-id'));
};

var slidePositionVieved = new Object();
//owlstats ga360 slider stats slider
var owl = $('.owl-carousel').each(function () {
  //sprawdzamy, czy jest ustawiony id dla statystyk
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  var ga360id = $(this).data('ga360-id');
  if (ga360id == undefined) {
    console.log(
      '%c dodać ga360-id do slidera #' + $(this).data('id'),
      'background: #553311; padding: 3px; color: #ffaaaa'
    );
  } else {
    statElementCollector[ga360id + '-' + $artID] = {
      id: ga360id,
      moduleName: $(this).data('id'),
      viewedCount: 0,
      itemCount: $(this).find('.owl-item:not(.cloned)').length,
    };
  }
  $(this).statVievedSlide = [];
  //pozycje
  $(this)
    .find('.owl-item:not(.cloned)')
    .each(function (n) {
      $(this).attr('idx', n + 1);
    });
  slidePositionVieved[ga360id + '-' + $artID] = {};
});

function ga360Test() {
  var statElementArray = [];

  for (var pos in statElementCollector) {
    statElementArray.push(statElementCollector[pos]);
  }

  //console.log(statElementArray);
}

owl.find('.owl-next, .owl-prev').on('click', function (event) {
  var $owl = $(this).parents('.owl-carousel');
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  var $gId = $(this).parents('.owl-carousel').data('id');
  var $acId = $owl.find('.owl-item.center').find('.owl-img>img').attr('src');
  //ga360
  var ga360id = $owl.data('ga360-id');
  var activeItem = $owl.find('.owl-item.center').attr('idx');
  slidePositionVieved[ga360id + '-' + $artID][activeItem] = 1;
  statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
    slidePositionVieved[ga360id + '-' + $artID]
  ).length;

  sendStats('gallery', 'click', $artID + ' / ' + $gId, $acId);
  WP.push(function () {
    WP.pwp.customAction({
      ga: {
        event: 'content_interaction',
        eventCategory: ga360id,
        moduleName: $gId,
        eventAction: 'click',
      },
    });
  });
});

owl.find('.owl-dot').on('click', function (event) {
  var $owl = $(this).parents('.owl-carousel');
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  var $gId = $(this).parents('.owl-carousel').data('id');
  var $acId = $owl
    .find('.owl-item[idx="' + ($(this).index() + 1) + '"]')
    .find('.owl-img>img')
    .attr('src');
  //ga360

  var ga360id = $owl.data('ga360-id');
  var activeItem = $(this).index() + 1;
  slidePositionVieved[ga360id + '-' + $artID][activeItem] = 1;
  statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
    slidePositionVieved[ga360id + '-' + $artID]
  ).length;

  sendStats('gallery', 'click', $artID + ' / ' + $gId, $acId);
  WP.push(function () {
    WP.pwp.customAction({
      ga: {
        event: 'content_interaction',
        eventCategory: ga360id,
        moduleName: $gId,
        eventAction: 'click',
      },
    });
  });
});

owl.on('dragged.owl.carousel', function (event) {
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  var $gId = $(this).data('id');
  var $acId = $(this).find('.owl-item.center').find('.owl-img>img').attr('src');
  var ga360id = $(this).data('ga360-id');
  var activeItem = $(this).find('.owl-item.center').attr('idx');
  slidePositionVieved[ga360id + '-' + $artID][activeItem] = 1;
  statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
    slidePositionVieved[ga360id + '-' + $artID]
  ).length;
  sendStats('gallery', 'swipe', $artID + ' / ' + $gId, $acId);
  WP.push(function () {
    WP.pwp.customAction({
      ga: {
        event: 'content_interaction',
        eventCategory: ga360id,
        moduleName: $gId,
        eventAction: 'swipe',
      },
    });
  });
});

var viewData = [];

// flaga dla urządzeń z touch:
if ('ontouchstart' in document.documentElement) {
  var deviceTouch = true;
}

// flaga dla scrollowania:
var isScrolligFlag = false;
var idTS;

window.addEventListener('scroll', function () {
  isScrolligFlag = true;
  if (idTS) {
    clearTimeout(idTS);
  }
  idTS = setTimeout(function () {
    idTS = null;
    isScrolligFlag = false;
  }, 450);
});

$(document).on('click', '[data-st-click]', function () {
  var T = $(this).data('st-click');
  if (T) {
    if (T.value) {
      sendStats(T.category, T.action, T.label, T.value);
    } else {
      sendStats(T.category, T.action, T.label);
    }
  }
});
