'use strict';

(function () {
  if (document.querySelectorAll('.peel').length > 0) {
    document.querySelectorAll('.peel').forEach((el) => {
      const artID = el.closest('.__js_stats_wrapper').getAttribute('data-id');
      const ga360id = el.getAttribute('data-ga360-id');
      const moduleName = el.getAttribute('data-id');
      if (!ga360id) {
        console.log(
          '%c dodać data-ga360-id do peel-back jesli mają iść staty!',
          'background: #3b3b3b; padding: 3px; color: #beeb2f'
        );
      } else {
        statElementCollector[ga360id + '-' + artID] = {
          id: ga360id,
          moduleName: moduleName,
          viewedCount: 0,
          itemCount: 1,
        };
      }
      $(this).statVievedSlide = [];
      //pozycje
      slidePositionVieved[ga360id + '-' + artID] = {};

      // console.log(Peel.supported, Peel.effectsSupported, Peel.Corners);
      let peel;
      let setAll = function () {
        if (!el.classList.contains('peel--seen')) {
          peel = new Peel('#' + el.getAttribute('id'), {
            backShadowAlpha: 0.2,
            backReflection: true,
            backReflectionSize: 0.2,
            bottomShadowSize: 20,
            bottomShadowOffset: -4,
            bottomShadowDarkAlpha: 10,
          });
          peel.setFadeThreshold(0.9);
          peel.setCorner(Peel.Corners.BOTTOM_LEFT);
          peel.addPeelConstraint(Peel.Corners.TOP_RIGHT);
          peel.setPeelPosition(0.375 * el.offsetWidth, 0.8 * el.offsetHeight);
          peel.handleDrag(function (evt, x, y) {
            el.querySelector('.peel-back__btn').classList.add('hidden');
            el.classList.add('peel--pressed');
            this.setPeelPosition(x, y);

            peel.setPeelPath(
              x,
              y,
              0.7 * el.offsetWidth,
              0,
              0.83 * el.offsetWidth,
              -0.2 * el.offsetHeight,
              2.2 * el.offsetWidth,
              -2.2 * el.offsetHeight
            );
            peel.t = 0;
            var tween = new TweenLite(peel, 1.5, {
              t: 1,
              paused: true,
              ease: Power2.easeIn,
              onUpdate: function () {
                peel.setTimeAlongPath(this.target.t);
                if (this.target.t === 1) {
                  el.classList.add('peel--seen');
                  sendStats(
                    'peel-back',
                    'view',
                    artID + ' / ' + moduleName,
                    el.querySelector('.peel-bottom img').getAttribute('src')
                  );

                  //skorka odslonieta
                  slidePositionVieved[ga360id + '-' + artID][1] = 1;
                  statElementCollector[ga360id + '-' + artID]['viewedCount'] =
                    Object.keys(
                      slidePositionVieved[ga360id + '-' + artID]
                    ).length;

                  WP.push(function () {
                    WP.pwp.customAction({
                      ga: {
                        event: 'content_interaction',
                        eventCategory: ga360id,
                        moduleName: moduleName,
                        eventAction: deviceTouch ? 'swipe' : 'drag',
                      },
                    });
                  });

                  peel.removeEvents();
                }
              },
            });
            if (peel.getAmountClipped() > 0.45) {
              tween.seek(0);
              tween.play();
            }
          });
        }
      };
      setAll();
      let clipPathNum;

      window.addEventListener('load', (event) => {
        clipPathNum = document.querySelectorAll(
          '.peel-svg-clip-element defs clipPath'
        ).length;
      });

      let changeSettings;
      function resizeFinished() {
        peel.removeEvents();
        setAll();
        document
          .querySelectorAll(
            '.peel-svg-clip-element defs clipPath:not(:nth-last-child(-n+' +
              clipPathNum +
              '))'
          )
          .forEach(function (el) {
            el.remove();
          });
      }
      window.addEventListener('resize', function () {
        if (window.innerWidth < 490) {
          clearTimeout(changeSettings);
          changeSettings = setTimeout(function () {
            resizeFinished();
          }, 200);
        }
      });
    });
  }
})();

// var doit;
// function resizedw() {
//   console.log('koniec');
// }
// window.onresize = function () {
//   clearTimeout(doit);
//   doit = setTimeout(function () {
//     resizedw();
//   }, 200);
// };
