const playerButton = document.querySelector('.player-button'),
      audio = document.querySelector('audio'),
      timeline = document.querySelector('.timeline'),
      soundButton = document.querySelector('.sound-button'),
      playIcon = `
        <img src="https://hm-longform-2023.wpcdn.pl/img/play.png">
      `,
      pauseIcon = `
        <img src="https://hm-longform-2023.wpcdn.pl/img/pause.png">
      `,
      soundIcon = `
        <img src="https://hm-longform-2023.wpcdn.pl/img/speaker.png">
      `,
      muteIcon = `
        <img src="https://hm-longform-2023.wpcdn.pl/img/mute.png">
      `;

function toggleAudio () {
  if (audio.paused) {
    audio.play();
    playerButton.innerHTML = pauseIcon;
  } else {
    audio.pause();
    playerButton.innerHTML = playIcon;
  }
}



function changeTimelinePosition () {
  const percentagePosition = (100*audio.currentTime) / audio.duration;
  timeline.style.backgroundSize = `${percentagePosition}% 100%`;
  timeline.value = percentagePosition;
}


function audioEnded () {
  playerButton.innerHTML = playIcon;
}


function changeSeek () {
  const time = (timeline.value * audio.duration) / 100;
  audio.currentTime = time;
}


function toggleSound () {
  audio.muted = !audio.muted;
  soundButton.innerHTML = audio.muted ? muteIcon : soundIcon;
}

if (typeof playerButton !== 'undefined' && playerButton !== null) {
  playerButton.addEventListener('click', toggleAudio);

  audio.ontimeupdate = changeTimelinePosition;

  audio.onended = audioEnded;

  timeline.addEventListener('change', changeSeek);

  soundButton.addEventListener('click', toggleSound);
}