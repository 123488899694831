$(".text__image .audio__btn").click(function(){
  // $(this).hide();
  $(this).parents(".audio-skrypt").find(".audio-player").css("display", "inline");
  $(this).parents(".audio-skrypt").find(".audio__btn").css("display", "none");
  $(this).parents(".audio-skrypt").find('.audio-player audio').get(0).play();

  var
      category = $(this).parents('.audio-skrypt').data('id'),
      ga360id = $(this).parents('.audio-skrypt').data('ga360-id'),
      label = $(this).parents('.__js_stats_wrapper').data('id'),
      value = $(this).parents('.audio-skrypt').find('audio').data('src');

  WP.push(function () {
      WP.pwp.customAction({
        ga: {
          event: 'content_interaction',
          eventCategory: ga360id,
          moduleName: label,      //$accID,
          eventAction: 'play',
        },
      });
    });

    sendStats(
      category,       // category, data-id-audioElementu
      'click-play',   // action, 'click-play'
      label,          // label, id-artykulu-rodzica / moduleName (12 to samo co 21)
      value           // value -- $(this)  !!source zrodla
    );
      console.log();
});