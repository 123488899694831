const setSize = function () {
  document.querySelectorAll('.section-head').forEach((el) => {
    // if (window.innerWidth > 1180) {
      let beforeHeight =
        el.offsetHeight +
        el.closest('.article-wrapper').querySelectorAll('.article-title')[0]
          .offsetHeight +
        el.closest('.article-wrapper').querySelectorAll('.article-lead')[0]
          .offsetHeight +
        el.closest('.article-wrapper').querySelectorAll('.article-author')[0]
          .offsetHeight -
        0;
      el.style.setProperty('--beforeSize', beforeHeight + 'px');
    // } else if (window.innerWidth < 1180 && window.innerWidth > 880) {
    //   let beforeHeight =
    //     el.closest('.article-wrapper').querySelectorAll('.article-title')[0]
    //       .offsetHeight +
    //     el.closest('.article-wrapper').querySelectorAll('.article-lead')[0]
    //       .offsetHeight +
    //     el.closest('.article-wrapper').querySelectorAll('.article-author')[0]
    //       .offsetHeight -
    //     10;
    //   // el.style.setProperty('--beforeTop', el.offsetHeight + 'px');
    //   // el.style.setProperty('--beforeSize', beforeHeight + 'px');
    // } else {
    //   let beforeHeight =
    //     el.closest('.article-wrapper').querySelectorAll('.article-title')[0]
    //       .offsetHeight +
    //     el.closest('.article-wrapper').querySelectorAll('.article-lead')[0]
    //       .offsetHeight +
    //     el.closest('.article-wrapper').querySelectorAll('.article-author')[0]
    //       .offsetHeight;
    //   el.style.setProperty('--beforeTop', el.offsetHeight + 'px');
    //   el.style.setProperty('--beforeSize', beforeHeight + 'px');
    // }
  });
  document.documentElement.style.setProperty(
    '--browserArea',
    window.innerHeight - document.querySelector('.header').offsetHeight + 'px'
  );
};

const setVars = function () {
  document.documentElement.style.setProperty(
    '--windowWidth',
    document.documentElement.clientWidth + 'px'
  );
};

window.addEventListener('DOMContentLoaded', () => {
  setSize();
  setVars();
  document.documentElement.style.setProperty('--textColor', '#333');
  document.documentElement.style.setProperty('--bgColor', '#fff');
});

window.addEventListener('load', () => {
  setSize();
  setVars();
});

window.addEventListener('resize', (event) => {
  setSize();
  setVars();
});
