var tmax_optionsGlobal = {
  // repeat: -1,
  repeatDelay: 0.65,
  // yoyo: true,
};

CSSPlugin.useSVGTransformAttr = true;

var tl = new TimelineMax(tmax_optionsGlobal),
  path = '.svg-text *',
  stagger_val = 0.0125,
  duration = 0.75;

$.each($(path), function (i, el) {
  tl.set($(this), {
    x: '+=' + getRandom(-500, 500),
    y: '+=' + getRandom(-500, 500),
    rotation: '+=' + getRandom(-720, 720),
    scale: 0,
    opacity: 0,
  });
});

var stagger_opts_to = {
  x: 0,
  y: 0,
  opacity: 1,
  scale: 1,
  rotation: 0,
  ease: Power4.easeInOut,
};

tl.staggerTo(path, duration, stagger_opts_to, stagger_val);

var $svg = $('svg');
window.addEventListener('scroll', function () {});
$svg.hover(
  function () {
    tl.timeScale(0.15);
  },
  function () {
    tl.timeScale(1);
  }
);

tl.pause();

function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}

const animatedElements = document.querySelectorAll(
  '._js_inViewport, .__js_preload, .section-title'
);

const obsCallback = function (entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      if (entry.target.classList.contains('_js_inViewport')) {
        entry.target.classList.add('__js_onScreen');
      }
      if (entry.target.classList.contains('section-title')) {
        tl.play();
      }
      if (entry.target.classList.contains('__js_preload')) {
        const srcS = entry.target.getAttribute('data-src');
        $(entry.target)
          .css('opacity', '.3')
          .attr('src', srcS)
          .animate({ opacity: 1 }, 250);
        entry.target.classList.remove('__js_preload');
        observer.unobserve(entry.target);
      }
    } else {
      if (
        entry.target.classList.contains('_js_inViewport') &&
        entry.target.classList.contains('__js_onScreen')
      ) {
        // entry.target.classList.remove('__js_onScreen');
        observer.unobserve(entry.target);
      }
      if (entry.target.classList.contains('section-title')) {
        tl.pause();
      }
    }
  });
};

const observer = new IntersectionObserver(obsCallback, {
  root: null,
  threshold: [0.1, 1],
  // rootMargin: '-10px'
});

animatedElements.forEach((elem) => {
  observer.observe(elem);
});
